import { Form, OverlayTrigger, Tooltip, Col } from "react-bootstrap";
import ButtonDeBase from '../../Components/ButtonsCustom.jsx';


function ControleNbLettre({ control , what, max, useWatch} )  {
    const cible = useWatch({ //https://react-hook-form.com/api/usewatch pour controler lors de la saisie
      control,
      name: what,
    });

    if(cible != null) {
        let lgSaisie = (isNaN(cible)) ? Number(cible.length) : Number(cible.toString().length); // si un chiffre était saisie ca ne fonctionnait pas...
        //console.log(`lgSaisie pour ${what}  ${cible}`, cible.length);
        let carRestant = max - lgSaisie;
        let retour =  carRestant >= 0 ? 
            <>caractères restants : {carRestant}</>
        : 
            <span className="alert-depassement">caractères restants : {carRestant}</span>;

        return <small className="form-text">{retour}</small>
    }
}


/**
 * Composant affichant un champ bootstrap "controle" (text ou date etc...)
 *
 * @component
 * @return { HTMLElement }
 * <FormControl id="LIB_ACTIONS_FORMATION" infosProp={props.infosProprietes["ACTIONS_FORMATION|LIB_ACTIONS_FORMATION"]} />
*/
function FormControl({ register, setValue, getValues, errors, control, useWatch, id, infosProp, style="", noCount, nolib, classSpecifique, ValDonneeTierce, sourceDonneeTierce, ...rest }) {
    var idElement = id;
    
//console.log("idElement", idElement);
//console.log("ValDonneeTierce", ValDonneeTierce);
    if(infosProp != null && infosProp.lib_form != null) {
        var placeholder  = infosProp.placeholder ;
        var editable = infosProp.editable;
        var libelle = infosProp.lib_form;
        var maxLength = infosProp.max_length;
        var isRequired = infosProp.required > 0? true : false;
        var type = infosProp.type;
        var aide = infosProp.aide;
    }
    //console.log(`noCount   ${noCount}, nolib  ${nolib}`);
    
    var asQuoi = "input";
    if(type == "textarea" || type == "textarea-riche") asQuoi = "textarea";

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          <div dangerouslySetInnerHTML={{ __html:  aide }}></div>
        </Tooltip>
    );

    var classInput = "";
    if(classSpecifique != null && classSpecifique != '') classInput = classSpecifique;
    if(maxLength != undefined && maxLength > 0) {
        if(maxLength < 3) classInput += "input-codes-2ch";
        else if(maxLength < 6) classInput += "input-codes-5ch";
    }
    if(nolib == 1 && isRequired) classInput += " required-inline"; // utile pour mettre l'étoile à droit de l'input quand on ne souhaite pas afficher le libellé associé

    var  minLengthDynamic = 1;
    if(maxLength > 5) minLengthDynamic = 3; // pas fameux pour PRIX_HORAIRE_TTC et prix_total_TTC qui peuvent avoir 6 et 10 mais bon...

    //pattern regex permettant d'interdire certains caractères https://openclassrooms.com/forum/sujet/regex-interdire-caracteres-speciaux-59371
    //  types au 18/01/23 : date date-specifique email heure number option other select tel text textarea textarea-riche text-no-ctrl-case url
    // nb : 3 lettres : [A-Za-z]{3} only  letters : /^[a-zA-Z]+$/ (cf https://stackoverflow.com/questions/41296668/how-do-i-add-validation-to-the-form-in-my-react-component)
    // test cool pour url : https://www.regextester.com/94502
    // pattern directement dans un input : pattern="[A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ]{1}.+" après, test validité js :  if (!data["LIB_PROFESSIONNALISANTS"].match(/^[A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ][^&_$*£`\#]+$/)) ...
    const patternDynamic = () => (
        type=="tel" ? /[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$/ :
        type=="url" ? /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ :
        type=="email" ?  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ :
        type=="date-specifique" ?  /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/ : /*/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/*/
        type=="text" ?                  /^[^_$*£`\#]+$/ : /* champ input, [A-ZÂÊÎÔÛÄËÏÖÜÀÆÇÉÈŒÙ0-9] représente la première lettre en maj, désactivé, forçage Maj coté api */
        
        //select * from eo_proprietes_champs e where type='textarea-riche' : au 270125 :  f.OBJECTIF_FORMATION f.CONTENU_FORMATION
        //type=="textarea-riche" ?        /^[^_$*£`#]+$/ : // & et \ en moins par rapport a textarea
          type=="textarea-riche" ?        /^(?!.*<\/?(?:img|IMG|font|FONT)[^>]*>)[^_$*£`#]*$/ :
        type=="text-no-ctrl-case" ?     /^[^&_$*£`\#]+$/ : // siret, uai, cp...
        //type=="textarea" &&             /^[^&_$*£`\#]+$/
        //  select * from eo_proprietes_champs e where type='textarea' -- f.resultats_attendus a.CONDITIONS_SPECIFIQUES a.MODALITES_ALTERNANCE a.rythme_formation
          //type=="textarea" &&             /^(?!.*<[^>]+>)[^&_$*£`\#]*$/ // test 278125 par rapport a ci-dessus :
                                                                        // ^(?!.*<[^>]+>) : lookahead négatif interdit toute chaîne contenant une balise HTML complète (c'est-à-dire une chaîne qui commence par < et se termine par > avec du texte ou des attributs entre les deux).
                                                                        // [[^&_$*£`\#]*$ : interdits spécifiques : NOTE QUE  + est devenu +
            type=="textarea" &&             /^(?!.*<\/?(?:img|IMG|font|FONT)[^>]*>)[^&_$*£`\#]*$/ // 280125: version préféréé à ci-dessus, interdit que certaines balises
            // ^(?!.*<\/?(?:img|span|div|font|article)[^>]*>) : lookahead négatif interdit les balises
            // [[^&_$*£`\#]*$ : interdits spécifiques : NOTE QUE  + est devenu +                                                           
    )

    const patternDynamicInfoErreur = () => (
        type=="tel" ? "Veuillez respecter le format XX XX XX XX XX" : 
        type=="url" ? "Veuillez indiquer une url conforme, ex : https://www.example.com" : 
        type=="email" ?  "Veuillez respecter le format aaaaa@ndd.xxx" : 
        type=="date-specifique"   ? "La date doit être au format DD/MM/YYYY." :
        type=="text" ?              "Les caractères ^ _ $ * £ ` \ # sont interdits." :
        type=="textarea-riche"    ? "Les caractères ^ _ $ * £ ` # sont interdits, ainsi que les balises html font et img." : 
        type=="text-no-ctrl-case" ? "Les caractères ^ & _ $ * £ ` \ # sont interdits." :        
        type=="textarea" &&         "Les caractères ^ & _ $ * £ ` \ # sont interdits, ainsi que les balises html font et img."
    )

    var typeFinal = type;
    if(type=="date-specifique") typeFinal ="text";
    else if(type=="textarea-riche") typeFinal ="textarea";
    else if(type=="text-no-ctrl-case") typeFinal ="text";


/* aurions pu mettre :
                plaintext= {editable == 0 && "plaintext"}
                readOnly= {editable == 0 && "readOnly"}
mais 1 ca ne marche pas pour textarea 2- ca n'empeche pas le code html de s'afficher

type= text password email file color date number tel url ... : https://developer.mozilla.org/fr/docs/Web/HTML/Element/input/number
pour largeur unput, aurion pu utiliser w-25 ou 50 75 100.. mais le spourcentage ne sont pas assez bien
*/
    return (
        <>
        {idElement == null ?
            <>idElement indefini</>
        : 
            <>
            <Form.Group 
                className= { isRequired ?  "mb-3 required" + style : "mb-3" + style }
                as={type == "date" ? Col : undefined} 
                md={type == "date" ? "2" : undefined}  
                controlId={idElement} > {/*controlId permet de mettre un for dans le label et un id identique dans le controle*/ }

                    {(libelle != "" && nolib != 1) && 
                    <>
                        <Form.Label>{libelle}</Form.Label>

                        {aide != null && aide != "" && 
                        <OverlayTrigger  placement="right" overlay={renderTooltip} > 
                            <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                        </OverlayTrigger>
                        }
                    </>
                    }

                    {editable == 0 && (asQuoi == "textarea" ||  type == "textarea-riche") ?
                        getValues(idElement) == null ? 
                            <div dangerouslySetInnerHTML={{  __html: "<div className='contenu-ht-fixe-txt-form'></div>"}}></div>
                        :
                            <div dangerouslySetInnerHTML={{  __html: "<div className='contenu-ht-fixe-txt-form'>" + getValues(idElement) + "</div>"}}></div>
                    :
                        <>
                            {/*attention ci-dessous juste pour les champ texte on désactive la soumission du formulaire si appui sur touche entrée enter */}
                            {type == 'text' ?
                            <Form.Control 
                                type='text'
                                {...register(idElement, { required: isRequired,  maxLength: maxLength, minLength: minLengthDynamic,
                                                        pattern: {
                                                            value: patternDynamic()
                                                        }, })}
                                placeholder={placeholder}
                                disabled= {editable == 0 && "disabled"}
                                as={asQuoi}
                                className={classInput}
                                min="0"
                                onKeyDown={(e) => { e.key === 'Enter'  && e.preventDefault(); }}     
                            />
                            :
                            <Form.Control 
                                type={typeFinal}
                                {...register(idElement, { required: isRequired,  maxLength: maxLength, minLength: minLengthDynamic,
                                                        pattern: {
                                                            value: patternDynamic()
                                                        }, })}
                                placeholder={placeholder}
                                disabled= {editable == 0 && "disabled"}
                                as={asQuoi}
                                className={classInput}
                                min="0" 
                            />
                            }
                            {nolib == 1 && isRequired && <><span className="required-inline-etoile">*</span><br /></>}
                            { /* si le libellé n'est pas affiché alors l'éventuelle aide doit s'afficher ici (attention il faudra alors pecifier une class inline comme input-date-inline) */}
                            {(libelle == "" || nolib == 1) && 
                                <>
                                    {aide != null && aide != "" && 
                                    <OverlayTrigger  placement="right" overlay={renderTooltip} > 
                                        <button className="simply" type='button'><span className="fas fa-circle-question"></span></button>
                                    </OverlayTrigger>
                                    }
                                </>
                            }
                        </>
                    }

                    {noCount!= 1 && editable == 1 && maxLength != undefined && maxLength > 0 &&
                        <ControleNbLettre control={control} what={idElement} max={maxLength}  useWatch={useWatch} />
                    }
                    {/* erreurs renvoyées par react-hook-form, errors.name && errors.name.type devrait aussi marcher
                     apparement il ne sait pas gérer d'autres erreurs */}
                    {errors?.[idElement] && (
                        <Form.Text className="text-danger">
                            {errors?.[idElement]?.type === "required" && <p>Cette information est requise</p>}
                            {errors?.[idElement]?.type === "pattern" && <p>{patternDynamicInfoErreur()}</p>}
                            {errors?.[idElement]?.type === "maxLength" && <p>Ce champs ne doit pas contenir plus de {maxLength} caractères</p>}
                            {errors?.[idElement]?.type === "minLength" && <p>Veuillez saisir plus de 3 caractères</p>}
                        </Form.Text>
                    )}
            </Form.Group>
            
            {/* partie dédiée à des données tierce qui peuvent etre utilisées pour coller directement */}
            {            
            sourceDonneeTierce !== undefined && sourceDonneeTierce != "" && getValues(idElement) != null &&
            <>
                {ValDonneeTierce == null  || typeof(ValDonneeTierce) === undefined ? 
                    <div className="complementInfo orange">[Aucune donnée {sourceDonneeTierce}]</div> 
                :
                    (ValDonneeTierce.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '’').toLocaleLowerCase('fr-FR') === 
                    getValues(idElement).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, '’').toLocaleLowerCase('fr-FR')) ?
                    <div className="complementInfo green">[Donnée {sourceDonneeTierce} identique]</div> 
                :    
                    <div className="complementInfo red">
                    <ButtonDeBase className="fas fa-circle-left" className2="" 
                                onClick={() => setValue(id, ValDonneeTierce)} title="Copier l'info {sourceDonneeTierce} dans le champ cible" />&nbsp;&nbsp; 
                
                    {ValDonneeTierce}
                    </div>
                }
            </>
            }

            </>
        }
        
        </>

    )
}

export default FormControl